import { render, staticRenderFns } from "./lists_edit.vue?vue&type=template&id=5e912b39&scoped=true&"
import script from "./lists_edit.vue?vue&type=script&lang=ts&"
export * from "./lists_edit.vue?vue&type=script&lang=ts&"
import style0 from "./lists_edit.vue?vue&type=style&index=0&id=5e912b39&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e912b39",
  null
  
)

export default component.exports